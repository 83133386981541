.footer h5{
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-transform: uppercase;
    text-shadow: 1px 0px rgb(253, 241, 219);
}
.footer img{
    height: 50px;
    width: 50px;
  }
  @media screen and (max-width: 767px) {
    .footer h5{
        font-size: 18px;
    }
    .footer img{
        height: 25px;
        width: 25px;
    }
}